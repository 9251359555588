const initialState = {
    loading: false,
    lineItems: [],
    error: null,
    siteLISearch:""
};

const siteLineItem = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_LINE_ITEMS_SUCCESS':
            return { ...state,lineItems: action.payload };
        case 'FETCH_LINE_ITEMS_FAILURE':
            return { loading: false, lineItems: [], error: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_SITE_LI_SEARCH':
            return { ...state, siteLISearch: action.payload };
        default:
            return state;
    }
};
export default siteLineItem;